import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IServico } from '@/models/Entidades/Cadastros/PrestacaoServicos/IServico';
import { IDTOServico } from '@/models/DTO/Cadastros/PrestacaoServicos/IDTOServico';
import {
  INotaFiscalServico, INotaFiscalServicoImpostoTotal, INotaFiscalServicoItem, INotaFiscalServicoItemImposto, INotaFiscalServicoPrestador, INotaFiscalServicoTomador,
} from '@/models/Entidades/Fiscal/INotaFiscalServico';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { EStatusNotaFiscalServico } from '@/models/Enumeradores/Fiscal/NotasFiscaisServicos/EStatusNotaFiscalServico';
import { ETipoRps } from '@/models/Enumeradores/Fiscal/NotasFiscaisServicos/ETipoRps';
import { EOrigemNotaFiscalServico } from '@/models/Enumeradores/Fiscal/NotasFiscaisServicos/EOrigemNotaFiscalServico';
import { ELocalPrestacaoServico } from '@/models/Enumeradores/Fiscal/NotasFiscaisServicos/ELocalPrestacaoServico';
import { EIssRetido } from '@/models/Enumeradores/Fiscal/NotasFiscaisServicos/EIssRetido';

/**
 * Serviço de Cadastro de Serviços
 * Fornece todas regras de negócios e lógicas relacionado a Tipo de custos.
 */

class ServicoServico implements IServicoBase<IServico> {
    endPoint = 'servicos';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOServico): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(objeto: IServico): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, objeto);
      return result.data;
    }

    public async alterar(objeto: IServico): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${objeto.codigo}`, objeto);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<IServico> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public apresentacaoLocalPrestacaoServico(localPrestacao: number): string {
      if (localPrestacao === 0) return 'No endereço do Tomador';
      if (localPrestacao === 1) return 'No endereço do Prestador';

      return '';
    }

    public apresentacaoIssRetido(issRetido: number): string {
      if (issRetido === 0) return 'ISS Retido';
      if (issRetido === 1) return 'Normal';
      if (issRetido === 2) return 'Substituição';
      return '';
    }

    public instanciarNotaFiscalServico(empresa: number, usuario: number): INotaFiscalServico {
      const notaFiscalServico = {} as INotaFiscalServico;
      notaFiscalServico.codigo = 0;
      notaFiscalServico.codigoEmpresa = empresa;
      notaFiscalServico.codigoUsuario = usuario;
      notaFiscalServico.dataEmissaoRps = UtilitarioData.obterDataAtual();
      notaFiscalServico.dataCompetencia = UtilitarioData.obterDataAtual();
      notaFiscalServico.status = EStatusNotaFiscalServico.Pendente;
      notaFiscalServico.tipoRps = ETipoRps.RPS;
      notaFiscalServico.serieRps = 'UNICA';
      notaFiscalServico.numeroRps = 0;
      notaFiscalServico.numero = 0;
      notaFiscalServico.origemNotaFiscalServico = EOrigemNotaFiscalServico.NotaFiscalServico;
      notaFiscalServico.naturezaOperacao = '1';
      notaFiscalServico.localServico = ELocalPrestacaoServico.Tomador;
      notaFiscalServico.detalhesPagamento = [];
      notaFiscalServico.servicos = [] as INotaFiscalServicoItem[];
      notaFiscalServico.prestador = {} as INotaFiscalServicoPrestador;
      notaFiscalServico.prestador.codigoPessoa = 0;
      notaFiscalServico.tomador = {} as INotaFiscalServicoTomador;
      notaFiscalServico.tomador.codigoPessoa = 0;
      notaFiscalServico.totalImpostos = {} as INotaFiscalServicoImpostoTotal;
      notaFiscalServico.totalImpostos.codigo = 0;
      notaFiscalServico.totalImpostos.codigoNotaFiscalServico = 0;
      notaFiscalServico.totalImpostos.totalBaseCalculo = 0;
      notaFiscalServico.totalImpostos.totalIss = 0;
      notaFiscalServico.totalImpostos.totalIssRetido = 0;
      notaFiscalServico.totalImpostos.totalPis = 0;
      notaFiscalServico.totalImpostos.totalCofins = 0;
      notaFiscalServico.totalImpostos.totalInss = 0;
      notaFiscalServico.totalImpostos.totalIr = 0;
      notaFiscalServico.totalImpostos.totalCsll = 0;
      notaFiscalServico.totalImpostos.totalCredito = 0;
      notaFiscalServico.totalImpostos.totalValorAproximadoTributos = 0;
      return notaFiscalServico;
    }

    public instanciarItemNotaFiscalServico(): INotaFiscalServicoItem {
      const servico = {} as INotaFiscalServicoItem;
      servico.codigo = 0;
      servico.codigoNotaFiscalServico = 0;
      servico.codigoServico = 0;
      servico.principal = true;
      servico.ufIncidencia = 'GO';
      const servicoImposto = {} as INotaFiscalServicoItemImposto;
      servicoImposto.codigo = 0;
      servicoImposto.codigoNotaFiscalServicoItem = 0;
      servicoImposto.baseCalculo = 0;
      servicoImposto.aliquotaIss = 0;
      servicoImposto.valorIss = 0;
      servicoImposto.issRetido = EIssRetido.Normal;
      servicoImposto.valorIssRetido = 0;
      servicoImposto.aliquotaPis = 0;
      servicoImposto.valorPis = 0;
      servicoImposto.aliquotaCofins = 0;
      servicoImposto.valorCofins = 0;
      servicoImposto.aliquotaInss = 0;
      servicoImposto.valorInss = 0;
      servicoImposto.aliquotaIr = 0;
      servicoImposto.valorIr = 0;
      servicoImposto.aliquotaCsll = 0;
      servicoImposto.valorCsll = 0;
      servicoImposto.valorDeducoes = 0;
      servicoImposto.valorOutrasRetencoes = 0;
      servicoImposto.descontoCondicionado = 0;
      servicoImposto.descontoIncondicionado = 0;
      servicoImposto.valorAproximadoTributos = 0;
      servico.impostos = servicoImposto;
      return servico;
    }
}
export default ServicoServico;
