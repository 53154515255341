import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, vShow as _vShow } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-20" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_5 = { class: "ant-row" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }
const _hoisted_8 = { class: "ant-row" }
const _hoisted_9 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_10 = { class: "ant-col ant-col-xs-24 ant-col-xl-18" }
const _hoisted_11 = { class: "ant-row" }
const _hoisted_12 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_13 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_14 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_15 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_16 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_17 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_18 = {
  style: {"display":"none"},
  class: "ant-row"
}
const _hoisted_19 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_20 = { class: "ant-row" }
const _hoisted_21 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_icone = _resolveComponent("icone")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_selecionar_local_prestacao_servico = _resolveComponent("selecionar-local-prestacao-servico")!
  const _component_selecionar_iss_retido = _resolveComponent("selecionar-iss-retido")!
  const _component_card = _resolveComponent("card")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_selecionar_empresa_cadastro_compartilhado = _resolveComponent("selecionar-empresa-cadastro-compartilhado")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_requisicao_modal = _resolveComponent("requisicao-modal")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      keyboard: false,
      centered: "",
      width: 900,
      visible: _ctx.modalBase.computedVisivel,
      "onUpdate:visible": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
      "wrap-class-name": "ss-modal-cadastros"
    }, {
      title: _withCtx(() => [
        _createTextVNode(" Cadastro de Serviço "),
        _createVNode(_component_a_switch, {
          checked: _ctx.state.servico.ativo,
          "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.servico.ativo) = $event)),
          style: {"float":"right"},
          "checked-children": "Ativo",
          "un-checked-children": "Inativo"
        }, null, 8, ["checked"])
      ]),
      closeIcon: _withCtx(() => [
        _createVNode(_component_icone, { nome: "voltar" })
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", null, [
          ((_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Incluir && _ctx.telaBase.permissaoDados.incluir)
                || (_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Visualizar && _ctx.telaBase.permissaoDados.alterar))
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("button", {
                  type: "button",
                  class: "ant-btn ant-btn-lg ant-btn-secondary",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.salvar(true)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvarNovo), 1)
                ]),
                _createElementVNode("button", {
                  type: "button",
                  class: "ant-btn ant-btn-lg ant-btn-primary ss-btn-acao-destaque",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.salvar(false)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvar), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_skeleton, {
          loading: _ctx.telaBase.carregando
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "vertical" }, {
              default: _withCtx(() => [
                _createVNode(_component_card, { titulo: "" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_a_form_item, {
                          label: "Descrição",
                          class: "ss-margin-campos",
                          rules: [{ required: true }]
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              maxlength: "250",
                              class: "ant-input",
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.servico.descricao) = $event))
                            }, null, 512), [
                              [_vModelText, _ctx.state.servico.descricao]
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_a_form_item, {
                          label: "Valor Padrão",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_campo_numerico, {
                              valor: _ctx.state.servico.valorPadrao,
                              "onUpdate:valor": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.servico.valorPadrao) = $event)),
                              monetario: true
                            }, null, 8, ["valor"])
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_a_form_item, {
                          label: "Local Padrão para Prestação do Serviço",
                          class: "ss-margin-campos",
                          rules: [{ required: true }]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_selecionar_local_prestacao_servico, {
                              valor: _ctx.state.servico.localPrestacaoServico,
                              "onUpdate:valor": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.servico.localPrestacaoServico) = $event))
                            }, null, 8, ["valor"])
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_a_form_item, {
                          label: "Como tributar o ISS?",
                          class: "ss-margin-campos",
                          rules: [{ required: true }]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_selecionar_iss_retido, {
                              valor: _ctx.state.servico.issRetido,
                              "onUpdate:valor": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.servico.issRetido) = $event))
                            }, null, 8, ["valor"])
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _createVNode(_component_card, { titulo: "ATIVIDADE ECONÔMICA - INFORMAÇÕES IGUAiS NA PREFEITURA" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_a_form_item, {
                              label: "Código",
                              class: "ss-margin-campos",
                              rules: [{ required: true }]
                            }, {
                              default: _withCtx(() => [
                                _withDirectives(_createElementVNode("input", {
                                  type: "text",
                                  maxlength: "30",
                                  class: "ant-input",
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.servico.codigoAtividadeEconomica) = $event))
                                }, null, 512), [
                                  [_vModelText, _ctx.state.servico.codigoAtividadeEconomica]
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_a_form_item, {
                              label: "Descrição",
                              class: "ss-margin-campos",
                              rules: [{ required: true }]
                            }, {
                              default: _withCtx(() => [
                                _withDirectives(_createElementVNode("input", {
                                  type: "text",
                                  maxlength: "250",
                                  class: "ant-input",
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.servico.descricaoAtividadeEconomica) = $event))
                                }, null, 512), [
                                  [_vModelText, _ctx.state.servico.descricaoAtividadeEconomica]
                                ])
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_card, { titulo: "ALÍQUOTAS/TRIBUTAÇÕES" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_a_form_item, {
                              label: "ISS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  valor: _ctx.state.servico.aliquotaIss,
                                  "onUpdate:valor": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.servico.aliquotaIss) = $event)),
                                  percentual: true
                                }, null, 8, ["valor"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_component_a_form_item, {
                              label: "PIS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  valor: _ctx.state.servico.aliquotaPis,
                                  "onUpdate:valor": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.servico.aliquotaPis) = $event)),
                                  percentual: true
                                }, null, 8, ["valor"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_a_form_item, {
                              label: "COFINS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  valor: _ctx.state.servico.aliquotaCofins,
                                  "onUpdate:valor": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.state.servico.aliquotaCofins) = $event)),
                                  percentual: true
                                }, null, 8, ["valor"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createVNode(_component_a_form_item, {
                              label: "INSS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  valor: _ctx.state.servico.aliquotaInss,
                                  "onUpdate:valor": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.state.servico.aliquotaInss) = $event)),
                                  percentual: true
                                }, null, 8, ["valor"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(_component_a_form_item, {
                              label: "IR",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  valor: _ctx.state.servico.aliquotaIr,
                                  "onUpdate:valor": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.state.servico.aliquotaIr) = $event)),
                                  percentual: true
                                }, null, 8, ["valor"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createVNode(_component_a_form_item, {
                              label: "CSLL",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  valor: _ctx.state.servico.aliquotaCsll,
                                  "onUpdate:valor": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.state.servico.aliquotaCsll) = $event)),
                                  percentual: true
                                }, null, 8, ["valor"])
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _createVNode(_component_a_form_item, {
                          label: "Informações adicionais",
                          class: "ss-margin-campos"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_textarea, {
                              value: _ctx.state.servico.informacoesAdicionais,
                              "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.state.servico.informacoesAdicionais) = $event)),
                              placeholder: "As informações digitadas aqui serão apresentadas na NFS-e.",
                              rows: 4
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _withDirectives(_createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_a_form_item, {
                          label: "Empresas",
                          class: "ss-margin-campos",
                          rules: [{ required: true }]
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_selecionar_empresa_cadastro_compartilhado, {
                              identificadorRecurso: _ctx.telaBase.identificadorRecurso,
                              codigosSelecionados: _ctx.telaBase.empresasSelecionadas,
                              "onUpdate:codigosSelecionados": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.telaBase.empresasSelecionadas) = $event))
                            }, null, 8, ["identificadorRecurso", "codigosSelecionados"])
                          ]),
                          _: 1
                        })
                      ])
                    ], 512), [
                      [_vShow, _ctx.telaBase.apresentarEmpresas]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_requisicao_modal, {
          visivel: _ctx.modalBase.exibirRequisicaoModal,
          "onUpdate:visivel": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.modalBase.exibirRequisicaoModal) = $event)),
          apresentarBarraProgresso: _ctx.modalBase.apresentarBarraProgresso,
          fraseBarraProgresso: _ctx.modalBase.fraseBarraProgresso,
          retornoRequisicao: _ctx.modalBase.retornoRequisicao
        }, null, 8, ["visivel", "apresentarBarraProgresso", "fraseBarraProgresso", "retornoRequisicao"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}